import React, { useState,useEffect } from 'react'
import { Search } from '../../components'
import axios from 'axios'
import getBaseURL from '../../config/baseURL'

const Workspaces = ({ token, openWorkspace }) => {
  const [search, setSearch] = useState('')
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const baseURL = getBaseURL()

  useEffect(()=>{
    let isCancelled = false;

    const fetchData = async () => {
      setLoading(true);
      try {
        const { data } = await axios.post(baseURL + '/oauth/workspace/list',
          { token })
        if (!isCancelled) {
          setData(data);
        }
      } catch (err) {
      } finally {
        if (!isCancelled) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isCancelled = true;
    };

  },[token])

  if (loading && !data) {
    return 'Loading..'
  }

  let workspaces = data || []

  if (search) {
    const s =search.toLowerCase()
    workspaces = data.filter(
      item => item.name.toLowerCase().includes(search.toLowerCase(s)) ||
        item.label.toLowerCase().includes(s))
  }
  return (<div className={'d-flex flex-column px-3 py-1'}>
    <Search onSubmit={setSearch}/>
    {workspaces.map(item => (<button
      className={'btn dart-btn-outline rounded text-left w-100 my-2 cursorPointer'}
      onClick={() => {
        openWorkspace(item.name, token)
      }}
    >
      {item.logo && (<img src={`${baseURL}/${item.name}/` + item.logo}
                          alt={item.name} className={'mr-2'}
                          style={{
                            maxWidth: 21, verticalAlign: -5,
                          }}/>)}
      {item.label || item.name}
    </button>))}
  </div>)
}
export default Workspaces
