import React, { Fragment, useEffect, useState } from 'react'
import KnacklyLogo from '../../images/knackly.svg'
import ActionstepLogo from '../../images/Actionstep.svg'
import { ReactComponent as Microsoft } from '../../images/icons/microsoft.svg'
import styled from 'styled-components'
import _ from 'lodash'
import { Menu, Dropdown } from 'antd'
import classNames from 'classnames'

import isTenancy from '../../utils/isTenancy'
import { useHistory } from 'react-router-dom'
import getBaseURL from '../../config/baseURL'
import axios from 'axios'
import Workspaces from './Workspaces'

const LabelSignIn = styled.h2`
    text-align: center;
    color: ${props => props.theme.colorTable.text};
`
const SocialLogin = styled.button`
    background: white !important;
    border-color: ${props => props.theme.colorTable.widget} !important;
    color: ${props => props.theme.colorTable.text} !important;
    font-weight: 500 !important;
    padding: 12px !important;
`

const Welcome = ({ redirectTenancy }) => {
  const { location: { state } } = useHistory()
  const [value, setValue] = useState(() => {
    const lastWorkspace = localStorage.getItem('lastWorkspace')
    if (lastWorkspace) {
      return lastWorkspace
    }
    return ''
  })
  const [payload, setPayload] = useState(null)
  const [error, setError] = useState('')
  const [options, setOptions] = useState([])

  const massage = state && state.massage

  const fillOptions = async () => {
    const tokensStr = localStorage.getItem('tokens')
    if (tokensStr) {
      const tokens = JSON.parse(tokensStr)
      let Accounts = Object.keys(tokens)
      if (Accounts.length) {
        const AccountsVerify = await Promise.all(
          Accounts.map((Account) => isTenancy(Account)))
        const AccountsWithOurError = AccountsVerify.filter(
          Account => !('error' in Account))
        if (AccountsWithOurError.length !== AccountsVerify.length) {
          const nextTokens = AccountsWithOurError.reduce(
            (previousValue, currentValue) => {
              return {
                ...previousValue,
                [currentValue.name]: tokens[currentValue.name],
              }
            }, {})

          localStorage.setItem('tokens', JSON.stringify(nextTokens))
          Accounts = Object.keys(nextTokens)
        }

        setOptions(_.orderBy(Accounts, _.initial, 'asc'))
      }
    }
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.has('p')) {
      const _payload = JSON.parse(searchParams.get('p') || '{}')
      if (_payload.workspaces.length === 1) {
        openWorkspace(_payload.workspaces[0], _payload.token, true)
      } else {
        setPayload(_payload)
      }
    } else {
      fillOptions()
    }
  }, [])

  const openWorkspace = async (workspace, token, isOnlyOne = false) => {
    let saveData = (_w, _t) => {
      let tokens = localStorage.getItem('tokens')
      let newInstansTokens = {}
      if (tokens) {
        tokens = JSON.parse(tokens)
        newInstansTokens = { ...tokens }
      }
      localStorage.removeItem('tokens')
      tokens = JSON.stringify({
        ...newInstansTokens,
        [_w]: _t,
      })
      localStorage.setItem('tokens', tokens)
      localStorage.setItem('lastWorkspace', _w)
      window.location.replace(window.location.origin + '/' + _w)
    }
    if (isOnlyOne) {
      saveData(workspace, token)
    } else {
      const { data } = await axios.post(getBaseURL() + '/oauth/workspace',
        { workspace, token })
      saveData(workspace, data)
    }
  }

  const onChangeValue = (event) => {
    event.preventDefault()
    const nextValue = event.target.value
    setValue(() => nextValue)
    const test = /(\W|[A-Z])/.test(nextValue)
    if (test) {
      setError('Sorry, only letters (a-z), numbers (0-9) are allowed.')
    } else {
      setError('')
    }
  }

  const onLogin = async (event) => {
    event.preventDefault()
    if (error) {
      return
    }
    const tenancy = await isTenancy(value)
    if (tenancy.isActive) {
      redirectTenancy(tenancy)
    } else {
      setError('We couldn’t find your workspace.')
    }
  }

  const onSelectOption = (option) => {
    setValue(option)
    setError('')
  }

  const redirectMicrosoft = (event) => {
    const URL = `${getBaseURL()}/oauth/microsoft?redirect=${window.location.origin}`
    window.location.replace(URL)
    event.stopPropagation()
  }

  const menu = (
    <Menu style={{
      maxHeight: 300,
      overflow: 'scroll',
    }}>
      {options.map(option => (
        <Menu.Item key={option} onClick={() => onSelectOption(option)}>
          {option}
        </Menu.Item>))}
    </Menu>)

  if (payload) {
    return (<Fragment>
        <div className={'w-100 text-center py-3'}>
          {window.location.host === 'www.actionstep-builder.com' ? (
            <img src={ActionstepLogo} style={{
              maxWidth: '20%',
              maxHeight: 100,
            }} alt={'Actionstep'}/>) : (<img src={KnacklyLogo} style={{
              maxWidth: '50%',
              maxHeight: 100,
            }} alt={'Knackly'}/>
          )}
        </div>
        <div className={'pt-5'}>
          <div className={'mx-auto welcome p-3 p-md-3'}>
            <Workspaces openWorkspace={openWorkspace} token={payload.token}/>
          </div>
        </div>
      </Fragment>
    )
  }
  return (
    <Fragment>
      <div className={'w-100 text-center py-3'}>
        {window.location.host === 'www.actionstep-builder.com' ? (
          <img src={ActionstepLogo} style={{
            maxWidth: '20%',
            maxHeight: 100,
          }} alt={'Actionstep'}/>) : (<img src={KnacklyLogo} style={{
            maxWidth: '50%',
            maxHeight: 100,
          }} alt={'Knackly'}/>
        )}

      </div>
      <div className={'pt-5'}>
        <div className={'mx-auto welcome p-4 p-md-5'}>
          {massage && (<div className="alert alert-danger mb-3" role="alert">
            Workspace <strong>{decodeURI(massage.toString())}</strong> not
            found!
          </div>)}
          <LabelSignIn
            className={'mb-3 font-weight-bold'}>Sign in to your
            workspace</LabelSignIn>
          <form onSubmit={onLogin}>
            <div className={'d-flex flex-column mb-3'}>
              <div className="form-group m-0 w-100">
                {options.length ? (<Dropdown overlay={menu}>
                  <input type="text"
                         className={classNames('form-control rounded-0',
                           { 'is-invalid': !!error })}
                         placeholder="Workspace name"
                         onChange={onChangeValue} value={value}
                         list={options.length ? 'AccountsList' : ''}
                  />
                </Dropdown>) : (<input type="text"
                                       className={classNames(
                                         'form-control rounded-0',
                                         { 'is-invalid': !!error })}
                                       placeholder="Workspace name"
                                       onChange={onChangeValue} value={value}
                                       list={options.length
                                         ? 'AccountsList'
                                         : ''}
                />)}
                <div id="feedbackName" className="invalid-feedback">
                  {error}
                </div>
              </div>
              <button type="submit" className="btn dart-btn w-100 mt-3"
                      style={{ fontSize: 12 }}
                      disabled={!value || error.length !== 0}>CONTINUE
              </button>
            </div>
          </form>
          <div className={'separate'}>
            <p>OR</p>
          </div>
          <div className={'signin shadow-none'}>
            <SocialLogin className={'socialLogin w-100 rounded text-left'}
                         onClick={redirectMicrosoft}
            >
              <Microsoft className={'iconSocial'}/>Sign in with
              Microsoft</SocialLogin>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Welcome
