import React, { useState, Fragment, useEffect } from 'react'
import { Modal } from 'antd'
import { IsInvalidIdentifier } from '../../utils'
import { AddButton, Input } from '../index'

const AddColumn = ({ addColumn, header, isQuery }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [name, setName] = useState('')
  const [error, setError] = useState('')

  useEffect(() => {
    if (isModalVisible) {
      setTimeout(() => {
        let input = document.getElementById('modalNewColumn')
        if (input) {
          input.focus()
        }
      }, 0)
    }
  }, [isModalVisible])

  const onCancel = () => {
    setIsModalVisible(false)
    setName('')
  }

  const onSubmit = () => {
    if (header.find(h => h.path === name)) {
      setError('A column by this name already exists.')
      return
    } else {
      addColumn(name)
    }
    setName('')
    setIsModalVisible(false)
  }

  if (isQuery) {
    return null
  }

  const isDisabled = (!name || IsInvalidIdentifier(name) || error)
  return (
    <Fragment>
      <Modal
        title="Add column"
        visible={isModalVisible}
        onCancel={onCancel}
        maskClosable={false}
        closable={false}
        width={410}
        footer={[
          <button key="submit"
                  className={'btn btn-secondary dart-btn'}
                  style={{ width: 125 }}
                  disabled={isDisabled}
                  onClick={onSubmit}
          >
            Ok
          </button>,
          <button key="back"
                  className={'btn btn-outline-secondary dart-btn-outline'}
                  style={{ width: 125 }}
                  onClick={onCancel}>Cancel</button>,
        ]}
      >
        <form onSubmit={event => {
          event.preventDefault()
          if (!isDisabled) {
            onSubmit()
          }
        }}>
          <Input
            type={'text'}
            value={name}
            onChange={(value) => {
              setName(value)
              if (error) {
                setError('')
              }
            }}
            label={'COLUMN NAME'}
            id={'modalNewColumn'}
            error={(name && IsInvalidIdentifier(name) || error)}
          />
        </form>
      </Modal>
      <div style={{ minWidth: 150 }}>
        <AddButton id={'Add column'}
                   label={'Add column'}
                   onClick={() => setIsModalVisible(true)}
        />
      </div>
    </Fragment>
  )
}

export default AddColumn
