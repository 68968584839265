import React, {
  Fragment,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import classNames from 'classnames'
import { OBJECT, SELECTION } from '../../../config'
import { ReactComponent as Note } from '../../../images/icons/sticky-note.svg'
import {
  ReactComponent as Question,
} from '../../../images/icons/question-circle.svg'
import { ReactComponent as ListOl } from '../../../images/icons/list-ol.svg'
import { ReactComponent as Bars } from '../../../images/icons/bars.svg'
import { ReactComponent as Trash } from '../../../images/icons/trash-alt.svg'
import {
  ReactComponent as ArrowsAltH,
} from '../../../images/icons/arrows-alt-h.svg'
import { Dropdown, Menu } from 'antd'
import { Icon } from '../../../components'
import { useDrag, useDrop } from 'react-dnd'
import { BodyLight, Card, MenuDelete, NavigationDark } from './style'
import ItemTypes from './ItemTypes'
import {
  GetDefaultControlStyle,
  InferLabelFromName,
  PluralizeStyle,
} from '../../../utils'
import TextArea from './TextArea'
import Visibility from './Visibility'
import ModalPosition from './modalPosition'
import ModalMove from './modalMove'

const ControlStyle = (varDef) => {
  const style = varDef.style ||
    GetDefaultControlStyle(varDef.type, varDef.isList)
  if (varDef.isList) {
    const listOf = 'list of '
    switch (varDef.type) {
      case OBJECT:
        return <Fragment>
          {listOf}
          <span className={'font-italic'}>{varDef.typeName}</span>
          {' ' + PluralizeStyle(style)}
        </Fragment>
      case SELECTION:
        return style === 'listbox' ? 'multi-select listbox' : style
      default:
        return listOf + PluralizeStyle(style)
    }
  } // else single item
  if (varDef.type === OBJECT) {
    return <Fragment>
      <span className={'font-italic'}>{varDef.typeName}</span>
      {' ' + style}
    </Fragment>
  } // else single non-object
  return style
}

const CardOfLayout = ({
  customRef,
  col,
  variables,
  content,
  classOfLayout,
  countColumns,
  changeContent,
  changeVisibility,
  isHovering,
  rowIndex,
  changeOffsetAndSpan,
  removeCard,
  row,
  previewRow,
  column,
  scrolling,
  isSelected,
  setSelected,
  removeSelected,
  moveRowTo,
  countRows,
}) => {
  const ref = useRef()
  const [left, setLeft] = useState(0)
  const [isModal, setModal] = useState(false)
  const [isModalMove, setModalMove] = useState(false)

  const updateSize = () => {
    if (ref) {
      if (ref.current) {
        setLeft(ref.current.getBoundingClientRect().width - 16)
      }
    }
  }
  useEffect(() => {
    updateSize()
  })
  useLayoutEffect(() => {
    updateSize()
    window.addEventListener('resize', updateSize)
  })

  const getPositionInHovering = monitor => {
    const hoverBoundingRect = ref.current.getBoundingClientRect()
    const clientOffset = monitor.getClientOffset()
    const widthItem = hoverBoundingRect.width / countColumns
    const positionInRootBlock = clientOffset.x - hoverBoundingRect.x

    return Math.floor(positionInRootBlock / widthItem)
  }

  let [
    {
      opacity,
    }, drag] = useDrag({
    item: {
      type: ItemTypes.CARD,
      row: rowIndex,
      column,
      content,
    },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.3 : 1,
      ClientOffset: monitor.getClientOffset(),
    }),
  })

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.CARD,
    drop ({
      column,
      row,
    }, monitor) {
      if (column) {
        return
      }
      if (content.span) {
        if (content.span <= 1) {
          return
        }
      }
      const position = getPositionInHovering(monitor)
      if (position > 0) {
        /*   add(getPositionInHovering(monitor)) */
      }
    },
    hover (_, monitor) {
      if (row.length === 1) {
        const position = getPositionInHovering(monitor)
        if (previewRow) {
          previewRow(position)
        }
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
  })
  console.log(column)
  const menu = (
    <Menu className={'menuLayout'}>
      <Menu.Item onClick={() => {
        setModal(true)
      }
      }>
        <ArrowsAltH style={{
          width: 12,
          marginBottom: 4,
        }}/> Position
      </Menu.Item>
      <Menu.Item onClick={() => {
        setModalMove(true)
      }
      }>
        <ArrowsAltH style={{
          width: 12,
          marginBottom: 4,
          transform: 'rotate(90deg)',
        }}/> Move to
      </Menu.Item>
      {(column && column.class && column.class !== 'question') &&
        (<Menu.Item onClick={() => {
          removeCard(rowIndex, column.id)
        }
        }>
          <Trash style={{
            width: 12,
            marginBottom: 2,
          }}/> Delete
        </Menu.Item>)}
    </Menu>
  )
  const onCancelModal = useCallback(() => setModal(false), [])
  const onSubmitModal = useCallback(nextValue => {
    changeOffsetAndSpan(nextValue, rowIndex, content.id)
    setModal(false)
  }, [isModal, rowIndex, content])

  const onCancelModalMove = useCallback(() => setModalMove(false), [])
  const onSubmitModalMove = useCallback(nextValue => {
    moveRowTo(nextValue)
    setModalMove(false)
  }, [])

  let IconForCondition
  if (classOfLayout === 'static' || classOfLayout === 'PreviewStatic') {
    IconForCondition = <Note style={{
      width: 16,
      height: 16,
    }}/>
  } else if (classOfLayout === 'question') {
    IconForCondition = <Question style={{
      width: 16,
      height: 16,
    }}/>
  }
  let body

  if (classOfLayout === 'question') {
    const variable = variables.find(v => v.name === content.content)
    if (variable) {
      if (variable.isList) {
        IconForCondition = <ListOl style={{
          width: 16,
          height: 16,
        }}/>
      }
      const typeOfIcon = Icon.fromValueType(
        variable.type)
      body = (<BodyLight className={'w-100 px-2 py-1 text-wrap'}>
        <div className={'d-flex flex-column'}>
          <div>{variable.label || InferLabelFromName(variable.name)}</div>
          <div>{ControlStyle(variable)}: <Icon
            type={typeOfIcon}
            className={'align-middle '} style={{ marginTop: -3 }}/> <span
            style={{ fontWeight: 600 }}>{variable.name}</span></div>
        </div>
      </BodyLight>)
    }
  } else {
    body = (<div className={'w-100 knackly-plain px-2 py-1 text-wrap'}>
      <div className={'d-flex flex-column'}>
        <TextArea changeContent={changeContent}
                  value={content.content}/>
      </div>
    </div>)
  }

  drag(drop(ref))
  if (classOfLayout === 'PreviewStatic') {
    opacity = 0.3
  }
  if (isOver || isHovering) {
    opacity = countColumns === 1 ? 1 : 0.2
  }
  if (isSelected) {
    opacity = 0.3
  }
  return (
    <div className={classNames(col)}>
      <Card className={'border rounded d-flex cardLayout text-truncate'}
            style={{ opacity }}
            ref={customRef || ref} onDrag={(event) => {
        event = event || window.event
        scrolling(event.pageY)
        event.preventDefault()
      }}
            onClick={event => {
              event = event || window.event
              if (event.ctrlKey || event.metaKey) {
                setSelected()
              } else {
                removeSelected()
              }
            }}
      >
        <NavigationDark
          className={'px-2 pt-1 rounded-left'}>{IconForCondition}</NavigationDark>
        {body}
        <NavigationDark
          style={{
            width: '25%',
            minWidth: 50,
          }}
          className={classNames(
            'pt-1 rounded-right',
            { 'knackly-back-dark-d': classOfLayout === 'question' },
          )}
        >
          <Visibility classOfLayout={classOfLayout}
                      content={content}
                      changeVisibility={changeVisibility}
                      variables={variables}/>
        </NavigationDark>
        <MenuDelete
          className={'position-absolute menuDeleteLayout knackly-back-dark'}
          style={{ left }}>
          <Dropdown overlay={menu}>
            <Bars style={{ width: '.75rem' }}/>
          </Dropdown>
        </MenuDelete>
      </Card>
      <ModalPosition isModal={isModal}
                     offset={content.offset ? content.offset : 0}
                     span={content.span ? content.span : 0}
                     row={row}
                     id={content.id}
                     onCancel={onCancelModal}
                     countColumns={countColumns}
                     onSubmit={onSubmitModal}/>
      {isModalMove && (<ModalMove
        isModal={isModalMove}
        currentPosition={rowIndex}
        currentColumn={content}
        currentCell={content.offset ? content.offset : 0}
        countColumns={countColumns}
        countRows={countRows}
        onCancel={onCancelModalMove}
        onSubmit={onSubmitModalMove}
      />)}
    </div>
  )
}

export default CardOfLayout
